<template>
  <header class="header">
    <div
      class="header__left"
      :class="{ 'is-collapsed': !sidebarExpanded || isMobileLayout }"
    >
      <slot name="left" />
    </div>
    <div
      class="header__nav-search"
      :class="{ 'sidebar-collapsed': !sidebarExpanded || isMobileLayout }"
    >
      <slot name="nav-search" />
    </div>
    <div class="header__actions-menu">
      <slot name="actions" />
    </div>
    <div class="header__profile-menu">
      <slot name="right" />
    </div>
    <div class="header__mobile-toggle">
      <slot name="mobile-menu-toggle" />
    </div>
  </header>
</template>

<script>
export default {
  props: {
    sidebarExpanded: {
      type: Boolean,
      default: false
    },
    isMobileLayout: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  height: var(--hp-header-height);
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--bs-html-bg);
  border-bottom: 1px solid var(--bs-border-color);
  display: flex;
  align-items: center;
  @extend %base-transition;
  z-index: $zindex-fixed + 1;

  @media (max-width: $hp-breakpoint-mobile-menu) {
    height: var(--hp-header-mobile-height);
  }
}

.header__left {
  display: flex;
  height: 100%;
  width: var(--hp-sidebar-width);
  padding: 0 0.75rem;

  @extend %base-transition;
  border-right: 1px solid transparent;

  &.is-collapsed {
    width: var(--hp-sidebar-collapced-width);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    border-right: 1px solid var(--bs-border-color);
  }
  @media (max-width: $hp-breakpoint-mobile-menu) {
    width: var(--hp-header-mobile-height) !important;
  }
}

.header__nav-search {
  padding-left: 1rem;
  max-width: 360px;
  width: 100%;
  @extend %base-transition;

  @media (max-width: $hp-breakpoint-mobile-menu) {
    max-width: 300px;
    &.sidebar-collapsed {
      padding-left: calc(var(--hp-header-mobile-height) - 42px);
    }
  }
}
</style>

<style lang="scss">
.header__mobile-toggle {
  min-width: var(--hp-header-mobile-height);
  border-left: 1px solid var(--bs-border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (min-width: $hp-breakpoint-mobile-menu) {
    display: none;
  }
}

.header__actions-menu {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 4px;
  margin-right: 12px;
}
.header__profile-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: max-content;
  height: 100%;
}
</style>
