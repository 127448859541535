<template>
  <aside
    v-if="!isMobileLayout"
    :class="{ 'is-expanded': expanded, 'is-collapsed-layout': isMobileLayout }"
  >
    <div>
      <div class="position-relative">
        <slot v-if="!isMobileLayout" name="fixed-top" />

        <slot v-if="!isMobileLayout" />
      </div>
    </div>
  </aside>
</template>

<script>
import Logo from '@/components/Layout/Logo.vue';

export default {
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Logo
  },

  computed: {
    isMobileLayout() {
      return (
        this.$screenState.md || this.$screenState.sm || this.$screenState.xs
      );
    }
  }
};
</script>

<style lang="scss" scoped>
aside {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  width: var(--hp-sidebar-collapced-width);
  height: 100%;
  padding-top: var(--hp-header-height);
  background: transparent;
  border-right: none;
  overflow: hidden;
  @extend %base-transition;

  @media (max-width: $hp-breakpoint-mobile-menu) {
    padding-top: var(--hp-header-mobile-height, 58px);
  }

  &.is-collapsed-layout {
    height: auto;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    z-index: 30;
    width: 1px;
    height: 140px;
    background: linear-gradient(
      180deg,
      var(--bs-border-color) 0%,
      transparent 100%
    );
  }

  & > div {
    width: 100%;
    height: 100%;
    overflow: auto;
    box-sizing: content-box;
    -webkit-overflow-scrolling: touch;
  }
}

.position-relative {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 20px);
}

.is-expanded {
  width: var(--hp-sidebar-width);

  .fixed-logo {
    width: var(--hp-sidebar-width);
  }
}

.fixed-logo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed + 1;
  width: var(--hp-sidebar-collapced-width);
  height: var(--hp-header-height);
  background: transparent;
  border-right: 1px solid var(--bs-border-color);
  @extend %base-transition;

  @media (max-width: $hp-breakpoint-mobile-menu) {
    height: var(--hp-header-mobile-height, 58px);
  }
}
</style>
