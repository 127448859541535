<template>
  <div class="round-loader" :style="sizeStyle">
    <svg
      viewBox="0 0 100 100"
      class="round-loader-svg"
      :width="svgSize"
      :height="svgSize"
    >
      <circle
        class="round-loader-arc round-loader-arc-1"
        cx="50"
        cy="50"
        r="40"
        fill="none"
        stroke-linecap="round"
      />
      <circle
        class="round-loader-arc round-loader-arc-2"
        cx="50"
        cy="50"
        r="40"
        fill="none"
        stroke-linecap="round"
      />
      <circle
        class="round-loader-arc round-loader-arc-3"
        cx="50"
        cy="50"
        r="40"
        fill="none"
        stroke-linecap="round"
      />
      <circle
        class="round-loader-arc round-loader-arc-4 round-loader-tail"
        cx="50"
        cy="50"
        r="40"
        fill="none"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  size: {
    type: [Number, String],
    default: 50
  }
});

const numericSize = computed(() => {
  if (typeof props.size === 'number') {
    return props.size;
  }
  if (typeof props.size === 'string' && !isNaN(Number(props.size))) {
    return Number(props.size);
  }
  return 50;
});

const svgSize = computed(() => '100%');

const sizeStyle = computed(() => {
  const sizeValue =
    typeof numericSize.value === 'number'
      ? `${numericSize.value}px`
      : props.size;

  return {
    width: sizeValue,
    height: sizeValue,
    '--loader-size': numericSize.value
  };
});
</script>

<style lang="scss" scoped>
.round-loader {
  --loader-stroke-width: 8;
  --loader-color-1: var(--bs-primary, #ff9f1a);
  --loader-color-2: rgba(var(--bs-primary-rgb), 0.64);
  --loader-color-3: rgba(var(--bs-primary-rgb), 0.3);
  --loader-color-4: var(--bs-border-color-dark, rgba(255, 114, 26, 0.8));
  --loader-anim-speed: 1s;
  --loader-rotation-speed: 2s;

  display: inline-block;
  position: relative;
  min-width: 10px;
  min-height: 10px;
  padding: 0;
  box-sizing: border-box;

  &-svg {
    animation: loader-rotate var(--loader-rotation-speed) linear infinite;
    width: 100%;
    height: 100%;
    display: block;
  }

  &-arc {
    stroke-width: var(--loader-stroke-width);
    stroke-dasharray: 0, 251;
    stroke-dashoffset: 0;
    transform-origin: center;

    &-1 {
      stroke: var(--loader-color-1);
      animation: loader-arc1 var(--loader-anim-speed) ease-in-out infinite;
    }

    &-2 {
      stroke: var(--loader-color-2);
      animation: loader-arc2 var(--loader-anim-speed) ease-in-out infinite;
    }

    &-3 {
      stroke: var(--loader-color-3);
      animation: loader-arc3 var(--loader-anim-speed) ease-in-out infinite;
    }

    &-4 {
      stroke: var(--loader-color-4);
      animation: loader-arc4 var(--loader-anim-speed) ease-in-out infinite;
      animation-delay: 0.2s;
    }
  }

  &-tail {
    opacity: 0.8;
  }

  @media screen and (max-width: 320px) {
    &-arc {
      stroke-width: max(
        1,
        calc(var(--loader-stroke-width) * (var(--loader-size) / 50))
      );
    }
  }
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-arc1 {
  0% {
    stroke-dasharray: 0, 251;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 80, 171;
    stroke-dashoffset: -45;
  }
  100% {
    stroke-dasharray: 0, 251;
    stroke-dashoffset: -251;
  }
}

@keyframes loader-arc2 {
  0% {
    stroke-dasharray: 0, 251;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100, 151;
    stroke-dashoffset: -95;
  }
  100% {
    stroke-dasharray: 0, 251;
    stroke-dashoffset: -251;
  }
}

@keyframes loader-arc3 {
  0% {
    stroke-dasharray: 0, 251;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 60, 191;
    stroke-dashoffset: -65;
  }
  100% {
    stroke-dasharray: 0, 251;
    stroke-dashoffset: -251;
  }
}

@keyframes loader-arc4 {
  0% {
    stroke-dasharray: 30, 221;
    stroke-dashoffset: -10;
  }
  50% {
    stroke-dasharray: 15, 236;
    stroke-dashoffset: -125;
  }
  100% {
    stroke-dasharray: 0, 251;
    stroke-dashoffset: -251;
  }
}
</style>
