<template>
  <transition name="slide">
    <div
      v-if="isOpen"
      class="mobile-sliding-menu"
      :class="[sideClass]"
      :style="{ width: width, maxWidth: maxWidth }"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MobileSlidingMenu',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    side: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'right'].includes(value)
    },
    menuId: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: '210px'
    },
    maxWidth: {
      type: String,
      default: null
    }
  },
  computed: {
    sideClass() {
      return `is-${this.side}`;
    }
  },
  methods: {
    handleCloseOthers(emitterId) {
      if (typeof emitterId !== 'string') {
        return; // Not a string, ignore
      }
      if (emitterId !== this.menuId && this.isOpen) {
        this.$emit('close');
      }
    },
    handleOverlayClick() {
      if (this.isOpen) {
        this.$emit('close');
      }
    }
  },
  mounted() {
    this.$bus.$on('mobile-menu:close-others', this.handleCloseOthers);
    this.$bus.$on('overlay-click', this.handleOverlayClick);
  },
  beforeUnmount() {
    this.$bus.$off('mobile-menu:close-others', this.handleCloseOthers);
    this.$bus.$off('overlay-click', this.handleOverlayClick);
  }
};
</script>

<style lang="scss" scoped>
.mobile-sliding-menu {
  position: fixed;
  top: var(--hp-header-height);
  left: 0;
  z-index: $zindex-modal - 1;
  height: calc(100% - var(--hp-header-height));
  font-size: 1rem;
  line-height: 1.7rem;
  background: var(--bs-body-bg);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin-top: 0;
  will-change: transform;

  &.is-right {
    left: auto;
    right: 0;
  }

  @media (max-width: $hp-breakpoint-mobile-menu) {
    top: var(--hp-header-mobile-height);
    height: calc(100% - var(--hp-header-mobile-height));
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  &.is-left {
    transform: translateX(-100%);
  }
  &.is-right {
    transform: translateX(100%);
  }
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}
</style>
