<template>
  <nav class="side-menu clearfix mb-5">
    <div
      class="side-menu__container clearfix"
      :class="{ 'mt-5': isMobileLayout }"
    >
      <router-link
        v-for="(item, index) in mainItems"
        :key="index"
        :to="item.path"
        class="side-menu__item"
        :class="{
          'd-none': hideDashboard && item.name === 'dashboard',
          'is-active': isActive(item.path)
        }"
        v-b-tooltip="{
          title: item.meta && item.meta.title ? item.meta.title : '',
          placement: 'right',
          disabled: expanded,
          delay: 0
        }"
      >
        <svg-icon :icon="item.meta && item.meta.icon" class="side-menu__icon" />
        <span v-if="expanded" class="side-menu__text">{{
          item.meta && item.meta.title
        }}</span>
      </router-link>

      <side-menu-group
        v-for="(item, id) in collapsingItems"
        :items="items"
        :id="id"
        :title="item.title"
        :icon="item.icon"
        :key="id"
        :list="item.items"
        :main-nav-expanded="expanded"
      />
    </div>
  </nav>
</template>

<script>
import SideMenuGroup from './SideMenuGroup.vue';

export default {
  name: 'SideMenu',
  components: {
    SideMenuGroup
  },

  props: {
    items: {
      type: Array,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      search: {
        placeholder: 'Search for Advertising Campaign',
        roles: ['bounty-hunter', 'bounty-manager']
      },
      collapsingItems: {
        dsp: {
          title: 'DSP-Integrations',
          icon: 'hp-git-pull-request'
        },
        match: {
          title: 'Matching',
          icon: 'hp-matching',
          items: [
            {
              path: '/admin/matching/browser',
              meta: {
                title: 'Browsers',
                icon: 'hp-browser'
              }
            },
            {
              path: '/admin/matching/device',
              meta: {
                title: 'Devices',
                icon: 'hp-devices'
              }
            },
            {
              path: '/admin/matching/device-type',
              meta: {
                title: 'Device types',
                icon: 'hp-devices-types'
              }
            },
            {
              path: '/admin/matching/os',
              meta: {
                title: 'OS',
                icon: 'hp-os'
              }
            }
          ]
        }
      }
    };
  },

  computed: {
    mainItems() {
      const keys = Object.keys(this.collapsingItems);
      const items = this.items.filter((item) => {
        let result = true;
        for (let i = 0; i < keys.length; i++) {
          result = !item.name.includes(keys[i]);
          if (!result) break;
        }
        return result;
      });

      return items;
    },

    hideDashboard() {
      return ['advertiser'].includes(this.$store.getters.role);
    },

    isMobileLayout() {
      return (
        this.$screenState.md || this.$screenState.sm || this.$screenState.xs
      );
    }
  },

  methods: {
    isActive(url) {
      const isHomeLink = url === '/';
      const isHomePage = this.$route.fullPath === '/';

      if (!isHomeLink && !isHomePage) {
        return this.$route.fullPath.indexOf(url) !== -1;
      } else if (isHomePage && isHomeLink) return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.side-menu {
  position: relative;
  margin-top: 12px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    max-height: 400px;
    background: linear-gradient(
      to bottom,
      var(--bs-border-color) 0%,
      transparent 100%
    );
  }
}

.side-menu__container {
  display: flex;
  flex-direction: column;
  gap: var(--hp-menu-gap);
  font-family: var(--hp-menu-font-family);
}

.side-menu__item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--hp-menu-item-padding-y) var(--hp-menu-item-padding-x);
  font-size: var(--hp-menu-font-size);
  color: var(--hp-menu-color);
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: var(--bs-border-radius);
  transition: none;

  .side-menu__icon {
    flex-shrink: 0;
    margin-right: var(--hp-menu-icon-gap);
    color: var(--hp-menu-icon-color);
    transition: color 0.2s ease;

    :deep(svg) {
      position: relative;
      top: -1px;
      width: var(--hp-menu-icon-size) !important;
      height: var(--hp-menu-icon-size) !important;
      padding: 0;

      & .hp-icon {
        stroke-width: 1.5 !important;
      }
    }
  }

  .side-menu__text {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;
  }

  &.is-active,
  &.router-link-active {
    color: var(--hp-menu-color-active);
    background-color: transparent;

    .side-menu__icon {
      color: var(--hp-menu-icon-color-active);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background-color: var(--hp-menu-color-active);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-radius: 0;
    }
  }

  &:hover {
    color: var(--hp-menu-color-hover);
    background-color: var(--bs-nav-link-hover-bg);

    .side-menu__icon {
      color: var(--hp-menu-icon-color-hover);
    }
  }
}
</style>
