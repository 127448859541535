<template>
  <div class="side-menu-group" v-if="isAdmin">
    <div class="side-menu-group__title" v-if="mainNavExpanded">
      <span class="side-menu-group__text">{{ title }}</span>
    </div>
    <hr class="side-menu-group__separator" v-else />

    <div class="side-menu-group__content">
      <template v-for="(item, index) in collapsingItems" :key="index">
        <router-link
          :to="item.path"
          :id="`side-menu-group-item-${id}-${index}`"
          class="side-menu-group__link"
          :class="{ 'is-active': $route.name === item.name }"
        >
          <svg-icon
            :icon="item.meta && item.meta.icon"
            class="side-menu-group__link-icon"
          />
          <span v-if="mainNavExpanded" class="side-menu-group__link-text">{{
            item.meta && item.meta.title
          }}</span>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideMenuGroup',

  props: {
    icon: {
      type: String,
      default: 'sites'
    },

    items: {
      type: Array,
      required: true
    },

    list: {
      type: Array,
      default: () => []
    },

    id: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    mainNavExpanded: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  computed: {
    isMobileLayout() {
      return (
        this.$screenState.md || this.$screenState.sm || this.$screenState.xs
      );
    },

    isAdmin() {
      return ['advert-admin', 'manager'].includes(this.$store.getters.role);
    },

    collapsingItems() {
      if (this.list.length > 0) return this.list;
      return this.items.filter((item) => item.name.includes(this.id));
    }
  }
};
</script>

<style lang="scss" scoped>
.side-menu-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
}

.side-menu-group__title {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--hp-menu-item-padding-y) var(--hp-menu-item-padding-x);
  margin-top: var(--hp-menu-item-gap-sm, 0.25rem);
  margin-bottom: var(--hp-menu-item-gap-sm, 0.25rem);
  font-size: var(--hp-menu-font-size-sm, 0.75rem);
  font-weight: var(--bs-font-weight-medium);
  color: var(--hp-menu-secondary-color);
  text-align: left;
  white-space: nowrap;
}

.side-menu-group__separator {
  width: calc(100% - 2 * var(--hp-menu-item-padding-x, 1rem));
  margin: 0.75rem var(--hp-menu-item-padding-x);
  border: 0;
  border-top: 1px solid var(--bs-border-color);
  opacity: 1;
}

.side-menu-group__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
}

.side-menu-group__link-icon {
  flex-shrink: 0;
  width: var(--hp-menu-icon-size);
  height: var(--hp-menu-icon-size);
  margin-right: var(--hp-menu-icon-gap);
  color: var(--hp-menu-icon-color);
  transition: color 0.2s ease;

  :deep(svg) {
    position: relative;
    top: -1px;
    width: var(--hp-menu-icon-size) !important;
    height: var(--hp-menu-icon-size) !important;

    & .hp-icon {
      stroke-width: 1.5 !important;
    }
  }
}

.side-menu-group__link {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--hp-menu-item-padding-y) var(--hp-menu-item-padding-x);
  margin-left: var(--hp-menu-item-indent, 0.5rem);
  font-size: var(--hp-menu-font-size);
  color: var(--hp-menu-color);
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: var(--bs-border-radius);
  transition: none;

  &:hover {
    color: var(--hp-menu-color-hover);
    background-color: var(--bs-nav-link-hover-bg);

    .side-menu-group__link-icon {
      color: var(--hp-menu-icon-color-hover);
    }
  }

  &.is-active {
    color: var(--hp-menu-color-active);
    background-color: var(--bs-nav-link-active-bg);

    .side-menu-group__link-icon {
      color: var(--hp-menu-icon-color-active);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background-color: var(--hp-menu-color-active);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-radius: 0;
    }
  }
}

.side-menu-group__link-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 1;
}

.menu-item-wrapper {
  position: relative;
  display: block;
  width: 100%;
}
</style>
