<template>
  <div class="profile-menu__dropdown-content dropdown-menu-content side-menu">
    <div v-if="isFakeLogin" class="text-success text-center py-2">
      Impersonated login as
    </div>
    <div class="profile-menu__dropdown-section dropdown-menu-section">
      <div
        class="profile-menu__user-info position-relative d-flex justify-content-start align-center px-5 py-3"
        v-if="user.email"
      >
        <div class="profile-menu__avatar">
          <avatar :username="fullName" />
        </div>
        <div class="profile-menu__details ps-3">
          <div class="profile-menu__name me-2">
            {{ fullName }}
          </div>
          <div class="profile-menu__email">
            {{ user.email }}
          </div>
          <b-badge variant="outline-tonal" class="badge-sm mt-2">
            {{ getRoleName(role) }}
          </b-badge>
        </div>
      </div>

      <template v-if="isAdmin">
        <hr class="dropdown-divider" />
        <a
          class="profile-menu__dropdown-item dropdown-item dropdown-item-sm"
          href="#"
          @click.prevent="$emit('open-fake-login-form')"
        >
          Login as ...
        </a>
      </template>

      <hr class="dropdown-divider" />
      <ThemeSwitcher class="px-3 py-3 w-100" />

      <template v-if="filteredProfiles.length > 0 && user.email">
        <hr class="dropdown-divider" />
        <a
          v-for="item in filteredProfiles"
          class="profile-menu__dropdown-item dropdown-item dropdown-item-sm"
          href="#"
          :key="item.email"
          @click.prevent="$emit('switch-user', item.email)"
        >
          {{ $t('switchTo') }} {{ item.email }}
        </a>
      </template>

      <template v-if="preparedAvailableRoles.length > 0">
        <hr class="dropdown-divider" />
        <a
          v-for="item in preparedAvailableRoles"
          class="profile-menu__dropdown-item dropdown-item dropdown-item-sm"
          href="#"
          :key="item.value"
          @click.prevent="$emit('switch-role', item.value)"
        >
          {{ $t('switchTo') }} {{ getRoleName(item.value) }}
        </a>
      </template>

      <hr class="dropdown-divider" />
      <router-link
        to="/edit"
        class="profile-menu__dropdown-item dropdown-item dropdown-item-sm"
        @click.native="$emit('close-menu')"
      >
        {{ $t('settings') }}
      </router-link>
      <router-link
        v-if="$can('visit', 'Payments')"
        to="/payments"
        class="profile-menu__dropdown-item dropdown-item dropdown-item-sm"
        @click.native="$emit('close-menu')"
      >
        {{ $t('payments') }}
      </router-link>
      <a
        href="#"
        class="profile-menu__dropdown-item dropdown-item dropdown-item-sm"
        @click="$emit('sign-out')"
        id="sign-out"
      >
        {{ $t('signOut') }}
      </a>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue';
import ThemeSwitcher from '@/components/ThemeSwitcher/ThemeSwitcher.vue';

export default {
  name: 'ProfileMenuContent',
  components: {
    Avatar,
    ThemeSwitcher
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    fullName: {
      type: String,
      required: true
    },
    role: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true
    },
    isFakeLogin: {
      type: Boolean,
      required: true
    },
    filteredProfiles: {
      type: Array,
      default: () => []
    },
    preparedAvailableRoles: {
      type: Array,
      default: () => []
    }
  },
  emits: [
    'open-fake-login-form',
    'switch-user',
    'switch-role',
    'close-menu',
    'sign-out'
  ],
  methods: {
    getRoleName(input) {
      if (!input) {
        return '';
      }
      let result = '';
      const words = input.split('-');
      words.forEach((word, index) => {
        if (index === 0) {
          result += word;
        } else {
          result += word.charAt(0).toUpperCase() + word.slice(1);
        }
      });
      // Assuming $t is globally available or provided via context/plugin
      return this.$t(result);
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-menu__dropdown-content {
  height: auto;
}

.profile-menu__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  .vue-avatar {
    width: 4rem;
    height: 4rem;
    min-height: 4rem;
    min-width: 4rem;
  }
}

.profile-menu__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  min-width: 0;
}
.profile-menu__name {
  font-size: 1rem;
  font-weight: 500;
}

.profile-menu__email {
  font-size: 0.9rem;
  color: var(--bs-body-color-medium);
  font-family: var(--font-roboto);
}
</style>
