<template>
  <div class="credential-settings d-flex align-items-center">
    <div class="d-none d-md-flex flex-column align-items-end">
      <div class="credential-email">{{ user.email }}</div>
      <div class="d-flex gap-2 align-items-center justify-content-end">
        <div v-if="showBalance" class="">
          <router-link
            v-if="!isPublisher && $can('visit', 'Payments')"
            to="/payments"
            class="balance-link text-decoration-none"
          >
            <template v-if="!isBalanceLoading">
              <svg-icon icon="hp-dollar" class="icon-balance" />
              <span
                class="align-center"
                :class="{ 'text-danger': isNegativeBalance }"
              >
                {{ formattedBalance }}
              </span>
            </template>
            <round-loader v-else size="16" />
          </router-link>
          <router-link
            v-if="isPublisher"
            to="/payments"
            v-b-tooltip.hover.bottom="'Unpaid earnings'"
            class="balance-link"
          >
            <template v-if="!isBalanceLoading">
              <svg-icon icon="hp-dollar" class="icon-balance" />
              <span>
                {{ formattedPublisherBalance }}
              </span>
            </template>
            <round-loader v-else size="16" />
          </router-link>
        </div>
        <b-badge v-if="isAdmin" variant="outline-tonal" class="badge-xs">
          Admin
        </b-badge>
        <b-badge v-else variant="outline-tonal" class="badge-xs">
          {{ getRoleName(role) }}
        </b-badge>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import RoundLoader from '@/components/UI/RoundLoader.vue';

export default {
  name: 'TheCredentialSettings',
  components: { RoundLoader },

  props: {
    showBalance: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters({
      role: 'role',
      user: 'user'
    }),
    ...mapState({
      userBalance: (state) => state.app.userBalance,
      publisherUnpaidBalance: (state) => state.app.publisherUnpaidBalance,
      isBalanceLoading: (state) => state.app.isBalanceLoading
    }),
    isAdmin() {
      return ['advert-admin', 'manager'].includes(this.role);
    },
    isPublisher() {
      return this.$can('show', 'unpaidBalance');
    },
    isNegativeBalance() {
      return this.userBalance.amount < 0;
    },
    formattedBalance() {
      const amount = this.userBalance.amount || 0;
      // Format to 2 decimal places
      const formattedAmount = amount
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return formattedAmount + ' ' + (this.userBalance.currency || '');
    },
    formattedPublisherBalance() {
      const amount = this.publisherUnpaidBalance.amount || 0;
      // Format to 2 decimal places
      const formattedAmount = amount
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return (
        formattedAmount + ' ' + (this.publisherUnpaidBalance.currency || '')
      );
    }
  },

  methods: {
    getRoleName(input) {
      if (!input) {
        return '';
      }
      let result = '';
      const words = input.split('-');
      words.forEach((word, index) => {
        if (index === 0) {
          result += word;
        } else {
          result += word.charAt(0).toUpperCase() + word.slice(1);
        }
      });
      return this.$t(result);
    }
  },

  created() {
    if (this.showBalance) {
      if (this.isPublisher) {
        this.$store.dispatch('fetchPublisherUnpaidBalance');
      } else if (this.$can('show', 'Balance')) {
        this.$store.dispatch('fetchUserBalance', this.user.id);
      }
    }
  },

  watch: {
    'user.id': {
      handler(val) {
        if (!val || !this.showBalance) return;
        if (this.isPublisher) {
          this.$store.dispatch('fetchPublisherUnpaidBalance');
        } else if (this.$can('show', 'Balance')) {
          this.$store.dispatch('fetchUserBalance', this.user.id);
        }
      }
    },
    role() {
      if (!this.showBalance) return;
      if (!['advertiser', 'publisher'].includes(this.role)) return;
      if (this.role === 'publisher') {
        this.$store.dispatch('fetchPublisherUnpaidBalance');
      } else {
        this.$store.dispatch('fetchUserBalance', this.user.id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.credential-settings {
  height: 100%;
  margin: 0 0.75rem;
}
.credential-email {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin: 0;
  font-size: 0.75rem;
  font-family: var(--font-roboto);
  color: var(--bs-body-color-muted);
  position: relative;
  bottom: 2px;
}

.balance-wrapper {
  display: flex;
  align-items: center;
}

.balance-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bs-body-color);
  font-size: 0.75rem;
  font-weight: bold;
}

:deep(.icon-balance) {
  svg {
    color: var(--bs-warning);
    width: 16px !important;
    height: 16px !important;
    margin: 0;
  }
}
</style>
