<template>
  <div v-if="isDev" class="header-control api-info text-center">
    <div class="api-info text-center" id="popover-api-info">
      <svg-icon
        icon="gear"
        class="icon-lg"
        :class="{ 'text-danger': !usingDevApis }"
      />
      <svg-icon
        v-if="usingDevApis"
        icon="check"
        class="icon-xs text-success status-icon"
      />
      <svg-icon
        v-else
        icon="question-circle"
        class="icon-xs text-danger status-icon"
      />
    </div>

    <b-popover
      target="popover-api-info"
      triggers="hover"
      placement="bottom"
      title="Api base URLs"
    >
      <div :class="{ 'text-danger': !this.authApiUrl.includes(baseDevUrl) }">
        <span class="text-medium">Auth:</span><br />{{ authApiUrl }}
      </div>
      <div
        class="mt-5"
        :class="{ 'text-danger': !this.adnetApiUrl.includes(baseDevUrl) }"
      >
        <span class="text-medium">Adnet:</span><br />{{ adnetApiUrl }}
      </div>
    </b-popover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseDevUrl: 'dev.api.hprofits'
    };
  },

  computed: {
    isDev() {
      return (
        process.env.NODE_ENV === 'development' ||
        window.location.origin.includes('dev.hprofits.com')
      );
    },

    authApiUrl() {
      return this.$api.auth.options.baseUrl;
    },

    adnetApiUrl() {
      return this.$api.adnet.options.baseUrl;
    },

    usingDevApis() {
      return (
        this.authApiUrl.includes(this.baseDevUrl) &&
        this.adnetApiUrl.includes(this.baseDevUrl)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.api-info {
  position: relative;
  width: 58px;
  cursor: pointer;

  .status-icon {
    position: absolute;
    right: 7px;
  }
}
</style>
