<template>
  <a @click="$router.push('/dashboard')" :title="$t('dashboard')" class="logo">
    <logo-image :is-minified="isMinified" :platform="platform" />
  </a>
</template>

<script>
import LogoImage from './Logos/LogoProfits.vue';

export default {
  name: 'Logo',
  components: {
    LogoImage
  },
  props: {
    isMinified: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      platform: process.env.PLATFORM
    };
  }
};
</script>

<style lang="scss" scoped>
.logo {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}
</style>
