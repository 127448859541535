<template>
  <div class="app-container" :class="{ 'is-collapsed-layout': isMobileLayout }">
    <the-header
      :sidebar-expanded="sidebarExpanded"
      :is-mobile-layout="isMobileLayout"
    >
      <template #left>
        <logo :is-minified="!sidebarExpanded || isMobileLayout" />
      </template>
      <template #mobile-menu-toggle>
        <the-mobile-menu
          :navigation-items="navigationItems"
          v-if="isMobileLayout"
        />
      </template>
      <template #nav-search>
        <navigation-search />
      </template>

      <template #right>
        <!-- <api-info /> -->
        <!-- <notification-center
          v-if="isAdmin"
          :notifications="notifications"
          :email-verified="emailVerified"
        /> -->
        <div
          class="header__actions"
          v-if="actionComponent.name && !isMobileLayout"
        >
          <component :is="actionComponent.name" class="d-none d-md-block" />
        </div>
        <the-credential-settings
          :show-balance="
            $can('show', 'Balance') || $can('show', 'unpaidBalance')
          "
        />
        <the-profile-menu />
      </template>
    </the-header>

    <the-sidebar :expanded="sidebarExpanded">
      <template #fixed-top>
        <button-sidebar-toggle
          :expanded="sidebarExpanded"
          @sidebar-toggle="sidebarExpanded = !sidebarExpanded"
        />
      </template>
      <SideMenu :items="navigationItems" :expanded="sidebarExpanded" />
    </the-sidebar>

    <div class="app-content">
      <notification />
      <app-error
        v-if="pageError"
        :title="pageError.status"
        :description="pageError.statusText"
      />
      <slot v-else></slot>
    </div>
    <overlay />
    <the-modals />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'; // Corrected lodash import
import { mapGetters, mapState } from 'vuex';

import ApiInfo from '@/components/ApiInfo.vue';
import ButtonCampaignAdd from '@/components/ButtonCampaignAdd.vue';
import ButtonSidebarToggle from '@/components/ButtonSidebarToggle.vue';
import Notification from '@/components/Notification/Notification.vue';
import Logo from '@/components/Layout/Logo.vue';
import TheHeader from '@/components/Layout/TheHeader.vue';
import TheHeaderTitle from '@/components/Layout/TheHeaderTitle.vue';
import TheMobileMenu from '@/components/Layout/SideMenu/TheMobileMenu.vue';
import TheProfileMenu from '@/components/ProfileMenu/TheProfileMenu.vue';
import TheCredentialSettings from '@/components/CredentialSettings/TheCredentialSettings.vue';
import TheSidebar from '@/components/Layout/TheSidebar.vue';
import NavigationSearch from '@/components/NavigationSearch/NavigationSearch.vue';
import Overlay from '@/components/Overlay.vue';
import TheModals from '@/components/TheModals.vue';
import TheUserBalance from '@/components/TheUserBalance.vue';
import { useHead } from '@/hook/useHead';
import AppError from '@/views/ErrorPages/index.vue';
import SideMenu from '@/components/Layout/SideMenu/SideMenu.vue';

export default {
  name: 'App',

  components: {
    TheHeader,
    TheHeaderTitle,
    TheSidebar,
    TheProfileMenu,
    TheCredentialSettings,
    TheMobileMenu,
    Overlay,
    SideMenu,
    ButtonSidebarToggle,
    NavigationSearch,
    ApiInfo,
    ButtonCampaignAdd,
    AppError,
    Logo,
    TheModals,
    Notification,
    TheUserBalance
  },
  data() {
    return {
      headerLeftComponent: {
        name: 'SearchField',
        props: {
          placeholder: 'Search for Advertising Campaign'
        }
      },
      sidebarExpanded: false
    };
  },

  computed: {
    ...mapGetters({
      notifications: 'notifications',
      pageError: 'pageError',
      role: 'role',
      emailVerified: 'emailVerified'
    }),
    ...mapState({
      isLoading: (state) => state.app.pageLoading
    }),
    isMobileLayout() {
      return (
        this.$screenState.md || this.$screenState.sm || this.$screenState.xs
      );
    },
    isAdmin() {
      return ['advert-admin', 'manager'].includes(this.$store.getters.role);
    },
    navigationItems() {
      return this.$store.getters.routes
        .filter((route) => {
          return (
            route.meta.roles.includes(this.$store.getters.role) ||
            (route.meta.grants && this.hasGrants(route.meta))
          );
        })
        .map((route) => {
          const newRoute = cloneDeep(route);
          newRoute.meta.title = this.$t(newRoute.meta.title);
          return newRoute;
        });
    },
    actionComponent() {
      let name = '';
      if (this.role === 'advertiser') {
        name = 'ButtonCampaignAdd';
      }
      return {
        name: name
      };
    }
  },

  methods: {
    hasGrants(routeMeta) {
      if (!this.$store.getters.user || !this.$store.getters.user.grants)
        return false;
      if (
        !routeMeta.grants ||
        !routeMeta.grants.list ||
        !routeMeta.grants.roles
      )
        return false;
      if (!routeMeta.grants.roles.includes(this.$store.getters.role))
        return false;

      let hasGrants = true;
      routeMeta.grants.list.forEach((grant) => {
        if (!this.$store.getters.user.grants.includes(grant)) {
          hasGrants = false;
          return;
        }
      });

      return hasGrants;
    },
    setHeadTitle() {
      const pageTitle = this.$route.meta.title;
      if (pageTitle) {
        useHead({
          title: this.$t(pageTitle)
        });
      }
    }
  },

  created() {
    this.setHeadTitle();
    this.sidebarExpanded = this.$store.getters.sidebarExpanded;
    // this.$store.dispatch('fetchNotifications', {}).catch(e => e);
    // setupFacebookSdk();
    let self = this;
    setTimeout(() => {
      self.$emit('updateHead');
    });
  },

  watch: {
    sidebarExpanded(val) {
      const handler = () => {
        this.$bus.$emit('sidebar:uncollapsed');
        window.removeEventListener('transitionend', handler);
      };
      window.addEventListener('transitionend', handler, false);

      val
        ? document.body.classList.add('is-sidebar-expanded')
        : document.body.classList.remove('is-sidebar-expanded');

      this.$store.dispatch('changeSidebarState', val);
    }
  }
};
</script>

<style lang="scss">
.app-content {
  padding: 1rem;
  width: 100%;
  height: 100%;
  background: var(--bs-html-bg);
}

.app-container {
  padding-top: var(--hp-header-height);
  padding-left: var(--hp-sidebar-collapced-width);
  background: var(--bs-html-bg);
  width: 100%;
  height: 100%;
  @extend %base-transition;

  @media (max-width: $hp-breakpoint-mobile-menu) {
    padding-top: var(--hp-header-mobile-height);
    padding-left: 0;
  }

  &.is-collapsed-layout {
    padding-left: 0;
  }
}

.is-sidebar-expanded {
  .app-container {
    padding-left: var(--hp-sidebar-width);
    @media (max-width: $hp-breakpoint-mobile-menu) {
      padding-left: 0;
    }
  }
}

aside.is-expanded {
  nav {
    i {
      width: fit-content;
    }
  }
}

.header__actions {
  height: 100%;
  border-right: 1px solid var(--bs-border-color);
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
</style>
