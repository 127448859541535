<template>
  <div class="mobile-menu">
    <button class="mobile-menu__button" @click="toggleMenuHandler()">
      <svg-icon
        :icon="isOpened ? 'hp-close' : 'hp-menu-dots'"
        class="icon-lg"
      />
    </button>
    <MobileSlidingMenu
      :is-open="isOpened"
      side="left"
      menu-id="TheMobileMenu"
      @close="closeMenuHandler"
    >
      <SideMenu
        :expanded="isOpened"
        :items="navigationItems"
        class="in-mobile-menu"
      />
    </MobileSlidingMenu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ButtonCampaignAdd from '@/components/ButtonCampaignAdd.vue';
import SideMenu from '../SideMenu/SideMenu.vue';
import MobileSlidingMenu from '../MobileSlidingMenu.vue';

export default {
  props: {
    navigationItems: {
      type: Array,
      default: () => []
    }
  },

  components: {
    SideMenu,
    ButtonCampaignAdd,
    MobileSlidingMenu
  },

  data() {
    return {
      isOpened: false,
      actionComponent: {
        name: 'ButtonCampaignAdd'
      }
    };
  },

  methods: {
    toggleMenuHandler() {
      const opening = !this.isOpened;
      this.isOpened = opening;
      if (opening) {
        this.$bus.$emit('mobile-menu:close-others', 'TheMobileMenu');
      }
      this.isOpened
        ? this.$bus.$emit('overlay-on')
        : this.$bus.$emit('overlay-off');
    },
    closeMenuHandler() {
      if (this.isOpened) {
        this.isOpened = false;
        this.$bus.$emit('overlay-off');
      }
    },
    ...mapGetters({
      role: 'role'
    })
  },

  watch: {
    $route() {
      this.closeMenuHandler();
    },
    role() {
      this.closeMenuHandler();
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile-menu {
  display: inline-block;
  vertical-align: middle;

  &__button {
    position: relative;
    z-index: $zindex-modal;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    color: var(--bs-icon-color);
    cursor: pointer;
    background: none;
    border: none;
    appearance: none;

    &:focus {
      outline: none;
    }

    svg {
      width: 18px;
      height: 18px;
      fill: currentColor;
    }
  }
}
</style>
