<template>
  <div class="profile-menu" v-click-outside="closeMenuHandler">
    <!-- Correct handler -->
    <b-button
      variant="link"
      @click="toggleDropdown()"
      id="profile-dropdown-btn"
      class="me-0 profile-menu__button"
      :class="{ 'is-mobile-control': isMobileLayout }"
    >
      <div class="profile-menu__button-content">
        <svg-icon
          :icon="!isFakeLogin ? 'hp-user' : 'hp-eye'"
          class="profile-menu__icon icon-lg icon-color-normal"
          :class="{
            'text-success': isFakeLogin
          }"
        />
      </div>
    </b-button>

    <MobileSlidingMenu
      v-if="isMobileLayout"
      :is-open="isOpen"
      side="right"
      menu-id="TheProfileMenu"
      @close="closeMenuHandler"
      width="90%"
      max-width="320px"
      class="side-menu"
    >
      <ProfileMenuContent
        :user="user"
        :full-name="fullName"
        :role="role"
        :is-admin="isAdmin"
        :is-fake-login="isFakeLogin"
        :filtered-profiles="filteredProfiles"
        :prepared-available-roles="preparedAvailableRoles"
        @open-fake-login-form="openFakeLoginForm"
        @switch-user="switchUser"
        @switch-role="switchRole"
        @close-menu="closeMenuHandler"
        @sign-out="signOut"
      />
    </MobileSlidingMenu>

    <!-- Desktop Version (Original Dropdown) -->
    <!-- Use ProfileMenuContent for desktop -->
    <div
      v-else-if="isOpen"
      class="profile-menu__dropdown dropdown-menu header-dropdown"
    >
      <ProfileMenuContent
        :user="user"
        :full-name="fullName"
        :role="role"
        :is-admin="isAdmin"
        :is-fake-login="isFakeLogin"
        :filtered-profiles="filteredProfiles"
        :prepared-available-roles="preparedAvailableRoles"
        @open-fake-login-form="openFakeLoginForm"
        @switch-user="switchUser"
        @switch-role="switchRole"
        @close-menu="closeMenuHandler"
        @sign-out="signOut"
      />
    </div>

    <fake-login-form v-if="isAdmin" ref="fake-login-form" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import * as config from '@/../config/index.js';
import FakeLoginForm from '@/components/FakeLoginForm.vue';
import * as auth from '@/utils/auth';
import MobileSlidingMenu from '../Layout/MobileSlidingMenu.vue'; // Corrected path
import ProfileMenuContent from './ProfileMenuContent.vue'; // Corrected path relative to current dir

export default {
  components: {
    FakeLoginForm,
    MobileSlidingMenu, // Register the wrapper
    ProfileMenuContent // Register the content component
  },

  data() {
    return {
      isOpen: false
    };
  },

  computed: {
    ...mapState({
      userBalance: 'userBalance'
    }),
    ...mapGetters({
      availableRoles: 'availableRoles',
      role: 'role',
      user: 'user',
      fullName: 'fullName'
    }),
    isAdmin() {
      return ['advert-admin', 'manager'].includes(this.$store.getters.role);
    },

    isFakeLogin() {
      const fakeLogin = auth.readFakeLogin();
      return fakeLogin.user ? fakeLogin.user === this.user.email : false;
    },

    preparedAvailableRoles() {
      return (
        this.availableRoles &&
        this.availableRoles
          .filter((el) => {
            return el !== this.role && el !== false;
          })
          .map((role) => {
            return {
              value: role,
              title: role
                .replace('-', ' ')
                .replace(/\b[a-z](?=[a-z]{2})/g, (letter) =>
                  letter.toUpperCase()
                )
            };
          })
      );
    },
    isMobileLayout() {
      return (
        this.$screenState.md || this.$screenState.sm || this.$screenState.xs
      );
    },
    filteredProfiles() {
      const profiles = [];
      const fakeLogins = auth.readFakeLoginsList();
      Object.keys(this.profiles).forEach((key) => {
        if (this.user.email !== key && !fakeLogins.includes(key)) {
          profiles.push({
            email: key,
            token: this.profiles[key]
          });
        }
      });

      return profiles;
    },
    profiles() {
      let profiles = localStorage.getItem('jwt-tokens');
      return JSON.parse(profiles);
    },
    refreshTokens() {
      let tokens = localStorage.getItem('jwt-refresh-tokens');
      return JSON.parse(tokens);
    }
  },

  // mounted and beforeUnmount are no longer needed for listeners

  watch: {
    // Watcher for overlay is simplified - only needed for desktop now
    isOpen(val) {
      if (!this.isMobileLayout && !val) {
        this.$bus.$emit('overlay-off');
      }
      // Mobile overlay handled by toggleDropdown
    },
    // Close on route change (applies to both mobile and desktop here)
    $route() {
      this.closeMenuHandler();
    },
    // Add/remove listeners if layout changes while component is mounted
    isMobileLayout(newVal, oldVal) {
      if (newVal === oldVal) return;
      // Ensure menu is closed when switching layouts
      this.closeMenuHandler();
    }
  },

  methods: {
    // Removed add/removeMobileListeners and handleCloseOthers
    ...mapActions({
      fetchTargets: 'advert/fetchTargets'
    }),
    getRoleName(input) {
      if (!input) {
        return '';
      }
      let result = '';
      const words = input.split('-');
      words.forEach((word, index) => {
        if (index === 0) {
          result += word;
        } else {
          result += word.charAt(0).toUpperCase() + word.slice(1);
        }
      });

      return this.$t(result);
    },
    switchRole(role) {
      this.$bus.$emit('switch-role:start');
      this.closeMenuHandler(); // Close menu before switching
      this.$store.dispatch('switchRole', role).then(
        (res) => {
          this.$bus.$emit('switch-role:end');
          if (!this.$route.meta.roles) return res;
          this.$router.push({
            name: this.getRouteName()
          });
          this.fetchTargets({ refresh: true });
          return res;
        },
        (e) => e
      );
    },
    switchUser(email) {
      auth.saveUser({ email });
      this.closeMenuHandler(); // Close menu before switching
      this.$store.dispatch('fetchUser').then(
        (res) => {
          if (res) {
            this.switchRole(config.default.roles.get(res.roles[0]));
            this.$router.go();
          }
        },
        (e) => e
      );
      this.$api.updateCredentials({
        email: email,
        token: this.profiles[email],
        refreshToken: this.refreshTokens[email]
      });
    },

    async openFakeLoginForm() {
      this.$refs['fake-login-form'].openForm();
    },
    toggleDropdown() {
      const opening = !this.isOpen;
      this.isOpen = opening;
      // Emit generic close event if opening on mobile
      if (this.isMobileLayout && opening) {
        this.$bus.$emit('mobile-menu:close-others', 'TheProfileMenu');
        // Handle overlay directly for mobile open
        this.$bus.$emit('overlay-on');
      } else if (!this.isMobileLayout && !opening) {
        // Handle overlay directly for desktop close
        this.$bus.$emit('overlay-off');
      } else if (this.isMobileLayout && !opening) {
        // Handle overlay directly for mobile close
        this.$bus.$emit('overlay-off');
      }
    },
    closeMenuHandler() {
      // Renamed from hideHandler for clarity
      if (!this.isOpen) return;
      const wasOpen = this.isOpen;
      this.isOpen = false;
      // Handle overlay directly for mobile close
      if (wasOpen && this.isMobileLayout) {
        this.$bus.$emit('overlay-off');
      }
    },
    signOut() {
      this.closeMenuHandler(); // Close menu before signing out
      this.$store.dispatch('logout');
    },
    getRouteName() {
      switch (auth.readActiveRole()) {
        case 'admin':
          return 'users';
        case 'ad-exchange':
          return 'stats';
        case 'advertiser':
          return 'stats';
        case 'advert-admin':
        case 'manager':
        default:
          return 'dashboard';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-menu {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
  @media (max-width: $hp-breakpoint-mobile-menu) {
    margin-right: 0;
    height: 100%;
    border-left: 1px solid var(--bs-border-color);
    width: var(--hp-header-mobile-height);
  }
}

.profile-menu__button {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bs-border-color);
  border-radius: 50%;
  border: none;
  color: var(--bs-body-color);
  font-size: var(--bs-body-font-size-sm);
  cursor: pointer;
  transition: all 0.2s ease;

  @media (max-width: $hp-breakpoint-mobile-menu) {
    background: transparent;
    border-radius: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  &:hover:not(:disabled) {
    border: 1px solid var(--bs-border-color);
    background: transparent;

    @media (max-width: $hp-breakpoint-mobile-menu) {
      border: none;
    }
  }

  &:active,
  &:focus {
    border-color: var(--bs-form-element-focus-border-color);
    box-shadow: var(--bs-form-element-focus-box-shadow);
    text-decoration: none;

    @media (max-width: $hp-breakpoint-mobile-menu) {
      border: none;
      box-shadow: none;
    }
  }
}

.profile-menu__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.profile-menu__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  min-width: 0;
}

.profile-menu__dropdown.header-dropdown {
  min-width: 320px;
  margin-top: calc(var(--bs-spacer) * 0.5);
  right: 0;
  left: auto;
  display: block;
  z-index: 1025;
  position: absolute;
  font-family: 'Onest', sans-serif;
  height: auto;
  top: 35px;
  transition: opacity 0.2s;
  opacity: 1;
  border: var(--bs-border-width) solid var(--bs-border-color);
  box-shadow: var(--bs-box-shadow);
}

.profile-menu__dropdown-content {
  height: auto;
}

.profile-menu__button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (max-width: $hp-breakpoint-mobile-menu) {
    width: 100%;
    height: 100%;
  }
}
</style>
